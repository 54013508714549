const Steps = {
  BASIC_INFO: "1",
  PROJECTS: "2",
  IMPORTCV: "3",
  EXPERIANCE: "4",
  EDUCATION: "5",
  CERTIFICATION: "6",
  ACCOUNT: "7",
  PROFILE: "8",
};

export default Steps;
