// import { axiosInstance } from "../../network/interceptors";
import Vue from "vue";
import store from "@/store";
const state = {
  stepOneData: {},
  stepTwoData: {},
  date: null,
  activeProject: JSON.parse(localStorage.getItem("activeProject")) || null,
  matchingFilter: {},
};
const mutations = {
  set_step_one_data(state, data) {
    state.stepOneData = data;
  },
  set_step_two_data(state, data) {
    state.stepTwoData = data;
  },
  set_booking_date(state, data) {
    state.date = data;
  },
  set_active_project(state, data) {
    state.activeProject = data;
  },
  set_matching_filter(state, { roleId, filter }) {
    Vue.set(state.matchingFilter, roleId, filter);
  },
};

const getters = {
  getStepOneData: (state) => state.stepOneData,
  getStepTwoData: (state) => state.stepTwoData,
  getDate: (state) => state.date,
  getActiveProject: (state) => state.activeProject,
  getMatchingFilter: (state) => {
    return state.matchingFilter || {};
  },
};

const actions = {
  async handleSetActiveProject({ getters, commit }, data) {
    if (
      getters.getActiveProject == null ||
      getters.getActiveProject.id != data.project.id
    ) {
      commit("set_active_project", data.project);
      localStorage.setItem("activeProject", JSON.stringify(data.project));
      if (data.projects.length > 1)
        store.dispatch("auth/setStep", data.project.step);
    }
  },
  saveMatchingFilter({ commit }, { roleId, filter }) {
    commit("set_matching_filter", { roleId, filter });
  },
  loadMatchingFilter({ commit, getters }, roleId) {
    const filter = getters.getMatchingFilter[roleId];
    if (filter) {
      let payload = { roleId, filter };
      commit("set_matching_filter", payload);
    } else {
      let initFilter = {
        qualified: null,
        "language[name]": null,
        "language[level]": null,
        seniority_level: null,
        industry: null,
        skills: [],
        countries: [],
        primary_technical_language: null,
        secondary_technical_language: null,
        availability: [],
      };
      let payload = { roleId, filter: initFilter };
      commit("set_matching_filter", payload);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
