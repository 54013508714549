
import {state} from "./reducer"
import {getters} from "./getter"
import {actions} from "./action"
import { mutations } from "./mutation";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };