<template>
  <div>
    <router-view> </router-view>
    <Zendesk v-if="profile && profile?.basicInfo?.hiring_status" />
  </div>
</template>
<script>
const Zendesk = () => import("@/components/zendesk/zendesk.vue");
import { mapGetters } from "vuex";
//-----------live-config-----------
//import LogRocket from "logrocket";
//---------------------------------
// import "./scss/base.scss"
export default {
  components: { Zendesk },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      profile: "talentProfile/getProfileData",
    }),
  },
  mounted() {
    //-----------live-config-----------
    if (process.env.VUE_APP_AWS_BASE_URL.includes("production")) {
      if (!window.hotjarLoaded) {
        window.hotjarLoaded = true;
      }
      /*  LogRocket.init("jzz6hc/squadio-portal-prod"); */
    }

    
    //-----------------------------------
    import('aos').then((AOS) => {
      AOS.init({
        duration: 1500,
        once: true,
      });
      window.addEventListener("scroll", function () {
        AOS.refresh();
      });
    });


    this.getProfileDataRequest();
    this.setAppLang();

  },
  methods: {
    async getProfileDataRequest() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.type == 1) {
        this.$store.dispatch("talentProfile/getProfileDataRequest");
      }
    },
    setAppLang() {
      const { locale } = this.$i18n;
      const { documentElement, body } = document;
      documentElement.setAttribute("lang", locale);
      if (locale == "en") {
        documentElement.setAttribute("dir", "ltr");
        body.classList.remove("rtl");
        if (window.gtag) { window.gtag("event", "language switch", { language: "english" }) }
      } else if (locale == "ar") {
        documentElement.setAttribute("dir", "rtl");
        body.classList.add("rtl");
        if (window.gtag) { window.gtag("event", "language switch", { language: "arabic" }) }
      }
    },

  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler() {
        this.getProfileDataRequest();
      },
    },
    "$i18n.locale"() {
      this.setAppLang();
    },
  },
};
</script>

<style lang="sass">
@import "./css/base.css"
@import "./scss/base.scss"
</style>