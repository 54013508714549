const ClientSteps = {
  ABOUT_PROJECT: "1",
  SELECT_SERVICE: "2",
  TECHNOLOGIES: "3",
  DETAILS: "4",
  BOOK_MEETING: "5",
  DONE: "6",
  PROFILE:"7"
};

export default ClientSteps;
