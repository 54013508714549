
export  const mutations = {
    get_project_types_respones(state, projectTypesOptions) {
      state.projectTypesOptions = projectTypesOptions;
    },
    get_profile_data_respones(state, profileData) {
        state.profileData = profileData;
      },
      get_role_skills(state, roleSkills){
        state.roleSkills = roleSkills
      },
      get_assessments(state, assessments){
        state.assessments = assessments
      },
      get_assessment_invitation(state, assessmentInvitation){
        state.assessmentInvitation = assessmentInvitation
      }
  };
