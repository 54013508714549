import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import client from './modules/client'
import talentProfile from "./modules/talent/profile/index"
import shared from './modules/shared'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        client,
        talentProfile,
        shared,
    }
})
