// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./flaticon.ttf?ba41b491a253f6a18cef91973179cc8d");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./flaticon.woff?ba41b491a253f6a18cef91973179cc8d");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./flaticon.woff2?ba41b491a253f6a18cef91973179cc8d");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./flaticon.eot?ba41b491a253f6a18cef91973179cc8d");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./flaticon.svg?ba41b491a253f6a18cef91973179cc8d");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#flaticon" });
// Module
exports.push([module.id, "@font-face{font-family:flaticon;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-display:swap}i[class*=\" flaticon-\"]:before,i[class^=flaticon-]:before{font-family:flaticon!important;font-style:normal;font-weight:400!important;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.flaticon-ic-icon-3:before{content:\"\\f101\"}.flaticon-ic-icon-2:before{content:\"\\f102\"}.flaticon-ic-icon-3-1:before{content:\"\\f103\"}.flaticon-ic-icon-4:before{content:\"\\f104\"}.flaticon-ic-icon-5:before{content:\"\\f105\"}.flaticon-ic-icon-6:before{content:\"\\f106\"}.flaticon-ic-icon-active-1:before{content:\"\\f107\"}.flaticon-logout:before{content:\"\\f108\"}.flaticon-user:before{content:\"\\f109\"}.flaticon-attachment:before{content:\"\\f10a\"}.flaticon-link:before{content:\"\\f10b\"}.flaticon-medal:before{content:\"\\f10c\"}.flaticon-portfolios:before{content:\"\\f10d\"}.flaticon-book:before{content:\"\\f10e\"}.flaticon-note:before{content:\"\\f10f\"}.flaticon-trash:before{content:\"\\f110\"}.flaticon-edit:before{content:\"\\f111\"}", ""]);
// Exports
module.exports = exports;
